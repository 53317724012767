
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "Icons",
  data() {
    return {
      iconList: [
        "account_circle_20px",
        "account_circle_20px@2x",
        "arrow_down_black_24px",
        "black_down_arrow_24px@2x",
        "chevron_right_40_24dp",
        "chevron_right_40_24dp@2x",
        "clear_results_20px",
        "clear_results_20px@2x",
        "clone_20px",
        "clone_20px@2x",
        "collapse_24px",
        "collapse_24px@2x",
        "company_24px",
        "company_24px@2x",
        "culture_24px",
        "culture_24px@2x",
        "delete_20px",
        "delete_20px@2x",
        "edit_20px",
        "edit_20px@2x",
        "email_24px",
        "email_24px@2x",
        "employees_24px",
        "employees_24px@2x",
        "expand_24px",
        "expand_24px@2x",
        "gray_help_24px@2x",
        "gray_help_24px",
        "job_24px",
        "job_24px@2x",
        "language_black_24dp",
        "language_black_24dp@2x",
        "logout_20px",
        "logout_20px@2x",
        "lock_black_20dp",
        "lock_black_20dp@2x",
        "more_vert_black_24dp",
        "more_vert_black_24dp@2x",
        "name_24px",
        "name_24px@2x",
        "navigate_left_32px",
        "navigate_left_32px@2x",
        "navigate_right_32px",
        "navigate_right_32px@2x",
        "navigate_right_black_24px",
        "navigate_right_black_24px@2x",
        "resend_invite_20px",
        "resend_invite_20px@2x",
        "timer_20px",
        "timer_20px@2x",
        "upload_24px",
        "upload_24px@2x",
        "search_24px",
        "search_24px@2x",
        "white_add_24px",
        "white_add_24px@2x",
        "white_ai_assist_24dp",
        "white_ai_assist_24dp@2x",
        "white_arrow_right_24px",
        "white_arrow_right_24px@2x",
        "white_block_32px",
        "white_block_32px@2x",
        "white_check_2_32px",
        "white_check_2_32px@2x",
        "white_check_24px",
        "white_check_24px@2x",
        "white_close_32px",
        "white_close_32px@2x",
        "white_content_copy_24dp",
        "white_content_copy_24dp@2x",
        "white_double_check_32px",
        "white_double_check_32px@2x",
        "white_edit_24dp",
        "white_edit_24dp@2x",
        "white_navigate_back_24px",
        "white_navigate_back_24px@2x",
        "white_publish_24px",
        "white_publish_24px@2x",
        "white_ready_32px",
        "white_ready_32px@2x",
        "white_send_24dp",
        "white_send_24dp@2x",
        "white_warning_32px",
        "white_warning_32px@2x",
      ],
      iconSVGList: [
        "white_link_solid",
        "white_email_solid",
        // new line
      ],
      analysisSectionIcons: [
        "bestpractice_black",
        "bestpractice_black@2x",
        "bestpractice_green",
        "bestpractice_green@2x",
        "bestpractice_red",
        "bestpractice_red@2x",
        "bestpractice_yellow",
        "bestpractice_yellow@2x",
        "clarity_black",
        "clarity_black@2x",
        "clarity_green",
        "clarity_green@2x",
        "clarity_red",
        "clarity_red@2x",
        "clarity_yellow",
        "clarity_yellow@2x",
        "inspiring_black",
        "inspiring_black@2x",
        "inspiring_green",
        "inspiring_green@2x",
        "inspiring_red",
        "inspiring_red@2x",
        "inspiring_yellow",
        "inspiring_yellow@2x",
        "practical_black",
        "practical_black@2x",
        "practical_green",
        "practical_green@2x",
        "practical_red",
        "practical_red@2x",
        "practical_yellow",
        "practical_yellow@2x",
      ],
    };
  },
  methods: {
    copyTag(value: string) {
      // :src="$utils('icons', 'image')"
      const imageTag = `<img src="@/assets/icons/${value}.png" alt="icons_${value}" />`;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(imageTag);
      ElMessage({
        message: "Copy image " + value + " to clipboard.",
        grouping: true,
        type: "success",
      });
    },
    copyName(value: string) {
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(value);
      ElMessage({
        message: "Copy name " + value + " to clipboard.",
        grouping: true,
        type: "success",
      });
    },
    imagePath(name: string) {
      return require("@/assets/icons/" + name + ".png") || "";
    },
    copyTagAnalysisSection(value: string) {
      const imageTag = `<img src="@/assets/image/AnalysisSectionIcons/${value}.png" alt="icons_${value}" />`;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(imageTag);
      ElMessage({
        message: "Copy image " + value + " to clipboard.",
        grouping: true,
        type: "success",
      });
    },
    imageSVGPath(name: string) {
      return require("@/assets/icons/" + name + ".svg") || "";
    },
    copySVGTag(value: string) {
      // :src="$utils('icons', 'image')"
      const imageTag = `<img src="@/assets/icons/${value}.svg" alt="icons_${value}" />`;
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(imageTag);
      ElMessage({
        message: "Copy image " + value + " to clipboard.",
        grouping: true,
        type: "success",
      });
    },
    imagePathAnalysisSection(name: string) {
      return (
        require("@/assets/image/AnalysisSectionIcons/" + name + ".png") || ""
      );
    },
  },
});
