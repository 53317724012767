
import { defineComponent } from "vue";

export default defineComponent({
  name: "ElementStyle",
  data() {
    return {
      iconList: "",
    };
  },
});
