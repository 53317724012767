import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icons = _resolveComponent("icons")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_element_style = _resolveComponent("element-style")!
  const _component_helloWorld = _resolveComponent("helloWorld")!
  const _component_test = _resolveComponent("test")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, { type: "border-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_el_tab_pane, { label: "Icons" }, {
        default: _withCtx(() => [
          _createVNode(_component_icons)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "ElementStyle" }, {
        default: _withCtx(() => [
          _createVNode(_component_element_style)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "HelloWorld" }, {
        default: _withCtx(() => [
          _createVNode(_component_helloWorld)
        ]),
        _: 1
      }),
      _createVNode(_component_el_tab_pane, { label: "Test" }, {
        default: _withCtx(() => [
          _createVNode(_component_test)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}