
import { defineComponent } from "vue";
import Icons from "@/views/DocsForDev/Icons.vue";
import HelloWorld from "@/components/HelloWorld.vue";
import ElementStyle from "@/views/DocsForDev/ElementStyle.vue";
import Test from "@/views/DocsForDev/Test.vue";

export default defineComponent({
  name: "DocsForDev",
  components: {
    Icons,
    ElementStyle,
    HelloWorld,
    Test,
  },
  mounted() {
    document.body.style.visibility = "visible";
  },
});
