
import { defineComponent } from "vue";
// import { ElMessage } from "element-plus";

export default defineComponent({
  name: "Test",
  data() {
    return {
      lang: "en",
    };
  },
  watch: {
    lang(value) {
      const setLocale = (lang: string) => {
        (this as any).$i18n.setLocale(lang);
      };
      setLocale(value);
      console.log(value);
      localStorage.setItem("lang", value.toUpperCase());
    },
  },
  // methods: {
  //   copyTag(value: string) {
  //     // :src="$utils('icons', 'image')"
  //     const imageTag = `<img src="@/assets/icons/${value}.png" alt="icons_${value}" />`;
  //     const clipboardData =
  //       (window as any).clipboardData || navigator.clipboard;
  //     clipboardData.writeText(imageTag);
  //     ElMessage({
  //       message: "Copy image " + value + " to clipboard.",
  //       grouping: true,
  //       type: "success",
  //     });
  //   },
  // },
});
